import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { BiLinkExternal, BiCaretRight } from "react-icons/bi"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar"
import MainWrapper from "../components/mainWrapper"
import Breadcrumb from "../components/breadcrumb"
import Links from "../components/links"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const Af = props => {
    return (
      <a href="/" className="link external">
        {props.text}
        <BiLinkExternal className="inline-block w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-4" />
      </a>
    )
  }
  const Afd = props => {
    return (
      <a href="/" className="link internal">
        {props.text}
        <BiCaretRight className="inline-block w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-4" />
      </a>
    )
  }
  const Notice = props => {
    return (
      <div className="border-2 border-red-500 py-2 pl-6 rounded relative">
        <span className="text-red-500 inline-block absolute -top-4 left-4 text font-bold bg-white px-2">
          {props.title}
        </span>
        {props.children}
      </div>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <div className="lg:flex lg:justify-between">
        <section>
          <MainWrapper>
            <article itemScope itemType="http://schema.org/Article">
              <div className="bg-white rounded p-4 md:p-8">
                <Breadcrumb title="人気ランキング" link="/" />
                <header>
                  <h1 itemProp="headline" className="font-bold md:text-xl my-8">
                    記事投稿用テンプレート ランキングページ版
                  </h1>
                </header>
                <StaticImage
                  layout="constrained"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../images/demo.jpg"
                  width={1280}
                  height={720}
                  quality={95}
                  alt="Profile picture"
                  itemProp="image"
                />
                <time
                  dateTime=""
                  className="text-right text-sm text-gray-700 mt-8 block font-bold"
                >
                  2021-01-01
                </time>
              </div>
              <div className="bg-white p-4 md:p-8 prose max-w-none">
                <Notice title="結論を先にまとめると">
                  <ol>
                    <li>1位がおすすめということを触れる</li>
                    <li>2位がおすすめということを触れる</li>
                    <li>3位がおすすめということを触れる</li>
                  </ol>
                </Notice>
                <p>
                  何がいいかわからない人に向けて、不安を同情する。
                  <br />
                  この記事内で解決できることを明確に伝える。
                  <br />
                  ここの文字数はそこまで多くない。
                  <br />
                </p>
              </div>
              <details
                open
                className="my-4 prose max-w-none bg-white rounded p-4 md:p-8"
              >
                <summary className="duration-300 font-bold text-sm marker:text-blue-400">
                  目次
                </summary>
                <nav>
                  <ol>
                    <li>【第1位】〇〇〇〇〇〇〇〇〇〇〇〇</li>
                    <li>【第2位】〇〇〇〇〇〇〇〇〇〇〇〇</li>
                    <li>【第3位】〇〇〇〇〇〇〇〇〇〇〇〇</li>
                    <li>【第4位】〇〇〇〇〇〇〇〇〇〇〇〇</li>
                    <li>【第5位】〇〇〇〇〇〇〇〇〇〇〇〇</li>
                    <li>まとめ</li>
                  </ol>
                </nav>
              </details>
              <section
                itemProp="articleBody"
                className="prose max-w-none bg-white rounded px-4 py-8 md:p-8"
              >
                {/* H2 */}
                <h2>【第1位】〇〇〇〇〇〇〇〇〇〇〇〇</h2>
                <StaticImage
                  layout="constrained"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  src="../images/rank.jpg"
                  width={1280}
                  height={720}
                  quality={80}
                  className="mb-8 md:mb-4"
                  alt="Profile picture"
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                  <Afd text="紹介ページを読む" />
                  <Af text="公式サイトを見る" />
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Corporis eligendi est culpa soluta similique at maiores, rem
                  facere maxime totam. Vel vero cum quas veritatis adipisci
                  ratione, minus eos velit.
                </p>
                {/* H3 */}
                <h3>【第1位】のポイント</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                  libero commodi enim, laboriosam beatae officiis? Excepturi ad
                  nulla veritatis ducimus asperiores quibusdam reiciendis quam,
                  dolore necessitatibus. Harum, tempore autem! Est.
                </p>
                {/* TABLE */}
                <table>
                  <tbody>
                    <tr>
                      <th className="bg-gray-100 pl-2">th</th>
                      <td>td</td>
                    </tr>
                    <tr>
                      <th className="bg-gray-100 pl-2">th</th>
                      <td>td</td>
                    </tr>
                    <tr>
                      <th className="bg-gray-100 pl-2">th</th>
                      <td className="text-yellow-300">★★★★★</td>
                    </tr>
                  </tbody>
                </table>
                {/* TABLE */}
                {/* H3 */}
                <h3>【第1位】のポイント</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                  libero commodi enim, laboriosam beatae officiis? Excepturi ad
                  nulla veritatis ducimus asperiores quibusdam reiciendis quam,
                  dolore necessitatibus. Harum, tempore autem! Est.
                </p>
                続く。。。
                <hr />
                <h2>まとめ</h2>
                <Notice title="記事のまとめ">
                  <ol>
                    <li>1位がおすすめということを触れる</li>
                    <li>2位がおすすめということを触れる</li>
                    <li>3位がおすすめということを触れる</li>
                  </ol>
                </Notice>
              </section>
            </article>
            <section>
              <Links link="/page/" title="記事一覧" />
            </section>
          </MainWrapper>
        </section>
        <section>
          <Sidebar />
        </section>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
        }
      }
    }
  }
`
